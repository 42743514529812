<template>
<div id="app">
    <audio id="audio" src="/notification.mp3" muted="true"></audio>

    <notifications position="top right" group="foo" class="notificacion-app lg" style="width: 417px; top: 21px; right: 24px">
        <template slot="body" slot-scope="{ item, close }">
            <div class="vue-notification-template notfClas d-flex flex-row" @click="onClick(item.data)">
                <div class="icono"><i class="far fa-check-circle"></i></div>
                <div class="contenido d-flex flex-column" style="width: 88%; margin-left: 21px">
                    <p class="title">{{ item.title }}</p>
                    <div class="body" v-html="item.text" />
                </div>
                <div class="cerrar">
                    <button class="close" @click="close">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
            </div>
        </template>
    </notifications>
    
    <!-- Vista para seleccionar la pripiedad al iniciar sesion  -->
    <select-property-login v-if="$store.state.userRoot && $store.state.userRoot.type != 'admin' && !$store.state.selectPropertyView && properties.length >= 2" /> 
    <!-- Loader del sistema principal  -->
    <loaderCarga v-if="$store.state.loader" />
    <!-- Modal de los datos a nivel global - obsoleto cambiar despues  -->
    <modalDatos />
    <!-- Router de vue  -->
    <router-view />
</div>
</template>

<script>
import loaderCarga from "./components/Loaders/loaderCarga.vue";
import SocketioService from "./services/socketio.service.js";
import modalDatos from "./views/Template/Maqueta/modalDatos.vue";
import './App.scss'
import SelectPropertyLogin from './components/selectPropertyLogin.vue';
import { mapState } from 'vuex';
export default {
    components: { loaderCarga, modalDatos, SelectPropertyLogin },
    created() {
      SocketioService.setupSocketConnection();
    },
    beforeUnmount() {
        SocketioService.disconnect();
    },
    data() {
        return {
            mensaje: "",
            nick: "",
            minombre: "",
            recibe: "",
        };
    },
    computed: {
    ...mapState("property", ["properties"]),
    },
    mounted() {
    
        // eslint-disable-next-line no-undef
        $(window).on("click", function () {});
        if (this.$store.state.userRoot) {
            // this.listarUser();
            SocketioService.socket.on("chat-message-" + this.$store.state.userRoot.id, (msg) => {
                // eslint-disable-next-line no-undef
                $("#audio").prop("muted", "");
                // eslint-disable-next-line no-undef
                $("#audio")[0].play();
                this.$funtion.Notifications();
                this.$store.state.state_notificacion = true;
                this.$notify({
                    title: msg.title,
                    text: msg.mensaje,
                    data: msg,
                    group: "foo",
                    duration: 5000,
                    classes: "notifications-active",
                });
            });
        } 
    },
    watch: {},

    methods: {
        onClick(data) {
            if (data.type == 1) {
                console.log(" data.type == 1 ---");
            } else {
                console.log(" data.type != 1 ---");
                this.$funtion.modalDatosGeneral(data.numero_documento);
            }
        },

    },
};
</script>
