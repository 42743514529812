<template>
<div>
    <hr>
    <ul class="list-group" v-animate-css="'pulse'" style="box-shadow: 0px 0px 11px 2px #eee; background: transparent">
        <li class="list-group-item d-flex" style="align-items: center;">
            <div class="nombre cursor d-flex flex-column" style="margin-left: 20px; width:200px">
                <small>{{ $t('empresa.listbookings.p28') }}</small>
                <span data-test="plan-title" class="d-flex flex-row" style="align-items: center">
                    <div style="font-weight: 600">{{ $t('empresa.listbookings.p29') }}</div>
                </span>
            </div>

            <div class="stateColumn cursor d-flex flex-column" style="" v-if="!$isMobile">
                <div>
                    <small>{{ $t('empresa.listbookings.p30') }}</small>
                    <span data-test="plan-title" class="d-flex flex-row" style="align-items: center">
                        <div style="font-weight: 600; color:#4caf50" v-if="state_deposit == 1">{{ $t('empresa.listbookings.p31') }}</div>
                        <div style="font-weight: 600; color:#FF9800" v-if="state_deposit == 2">{{ $t('empresa.listbookings.p32') }}</div>
                        <div style="font-weight: 600; color:#673ab7cf" v-if="state_deposit == 3">{{ $t('empresa.listbookings.p33') }}</div>  
                        <Skeleton width="90px" height="1rem" v-if="state_deposit== 0" />
                    </span>
                </div>

            </div>

            <div style="right: 90px; position: absolute; font-weight: 700">
                <small>{{
                $store.state.userRoot?.pais == "Colombia" ? "COP" : "EUR"
              }} </small>
                 {{ $funtion.formatearPrecio(Number(checkin?.programmed?.cantidad_payment), $store.state.userRoot?.pais) }}


            </div>
            <div class="opciones ml-auto d-flex flex-column" v-if="state_deposit == 1">
                <div class="dropdown" > 
                    <a class="btnstyl-sm btn-icon-only text-light d-flex" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="
                    justify-content: center;
                    align-items: center;">
                        <i class="fas fa-ellipsis-v" style="font-size: 13px"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <a href="#" @click.prevent="alertConfirmation" class="dropdown-item">
                            {{ $t('empresa.listbookings.p34') }}
                        </a>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
</template>

<script>
import axios from 'axios';
import Skeleton from 'primevue/skeleton';

export default {
    props: ['checkin'],
    components: {
        Skeleton
    },
    data() {
        return {
            state_deposit: 0
        }
    },
    mounted(){
        this.requestVerification()
    },
    methods: {
        async requestVerification() {
            let url = this.$base.dominio + '/request-verification-deposit';
            try {
                const sendData = {
                    programmed_id: this.checkin?.programmed.id
                }
                const response = await axios.post(url, sendData);
                console.log(response)
                if (response.data.is_refunded == true) {
                    this.state_deposit = 3;
                } 
                else if (response.data.payment_status == 'succeeded') {
                    this.state_deposit = 1;
                } else {
                    this.state_deposit = 2;
                }
            } catch (e) {
                console.log(e)
            } finally {
                console.log("Finalizo la petición requestVerification");
            }
        },
        alertConfirmation() {
            this.$swal({
                title: this.$t('empresa.listbookings.p35'),
                text: this.$t('empresa.listbookings.p36'),
                icon: "warning",
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: "gray",
                confirmButtonColor: "green",
                confirmButtonText: this.$t('empresa.listbookings.p35'),
                cancelButtonText: this.$t("empresa.listbookings.p17"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.state.loader = true;
                    this.refundDeposit()
                }
            });
        },
        async refundDeposit() {
            let url = this.$base.dominio + '/refund-deposit';
            try {
                const sendData = {
                    programmed_id: this.checkin?.programmed.id
                }
                const response = await axios.post(url, sendData);
                console.log(response.data);
                this.requestVerification();
            } catch (e) {
                console.log(e)
            } finally {
                this.$store.state.loader = false;
                console.log("Finalizo la petición refundDeposit");
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.stateColumn {
    margin-left: 20px;
    width: 200px;
    justify-content: center;
    text-align: left;
    align-items: center;
}
</style>
