const userDefaults = JSON.parse(localStorage.getItem("user")) || null;

const state = {
  userRoot: userDefaults,
  token: localStorage.getItem("access_token") || null,
  loader: false,
  loaderMensaje: "Cargando ...",
  efecto: false,
  efectoMensaje: "Cargando ...",
  consumo: 0,
  stateProcess: 1,
  notificaciones: [],
  state_notificacion: false,
  propiedadActiva: Number(localStorage.getItem("propiedadActiva") || 0),
  selectPropertyView: localStorage.getItem("selectPropertyView") || false,
  datosModal: null,
  checkins: 0,
  idioma: localStorage.getItem("appLanguage"),
  demo: true,
  // colores
  colorOne: "#11b14d",
  colorTwo: "#22b0d5",
  colorTree: "#ffcb2d",
  colorFor: "#ebeef1",
  server: "https://app.wacheckin.com/",
};

export default state;
